import React from 'react';

import {
    container,
    imgBox,
    ratio,
    separator,
    line,
    circle,
    signature,
    blue,
    pink,
    purple,
    yellow,
} from './client-opinion-card.module.scss';
import { IClientOpinion } from '../../models/client-opinion.model';
import { TAccentColor } from '../../models/accent-color.model';

import Semicircle from '../atoms/semicircle';
import ImageWithErrorPlaceholder from '../atoms/image-with-error-placeholder';

interface IClientOpinionCardProps {
    className?: string;
    color?: TAccentColor;
    client: IClientOpinion;
}

const ClientOpinionCard: React.FC<IClientOpinionCardProps> = ({
    className = '',
    client,
    color = 'blue',
}) => {
    const { media, content, name, position } = client;
    const colorClass = colorClasses[color];

    return (
        <div className={`${container} ${className} ${colorClass}`}>
            <ImageWithErrorPlaceholder
                className={imgBox}
                media={media}
                ratioClass={ratio}
                objectFit="contain"
            />
            <div className={separator}>
                <span className={line} />
                <Semicircle className={circle} color={color} orientation="bottom" />
            </div>
            <p>„{content}”</p>
            <div className={signature}>
                <p>~ {name},</p>
                <p>{position}</p>
            </div>
        </div>
    );
};

const colorClasses: Record<TAccentColor, string> = {
    blue: blue,
    pink: pink,
    purple: purple,
    yellow: yellow,
};

export default ClientOpinionCard;
