import React, { MouseEvent, useRef, useState } from 'react';
import { Link } from 'gatsby';

import {
    container,
    logoBox,
    logoRatio,
    numberBox,
    header,
    clientName,
    leadText,
    imageContainer,
    imageBackground,
    imageBox,
    ratio,
} from './project-card.module.scss';
import { relations } from '../../config/relations';
import { IProjectCard } from '../../models/project';

import ImageWithErrorPlaceholder from '../atoms/image-with-error-placeholder';

interface IProjectCardProps {
    className?: string;
    projectCard: IProjectCard;
    number?: number;
    ClientTag?: React.ElementType;
}

const SLOWING_FACTOR = 12;

const ProjectCard: React.FC<IProjectCardProps> = ({
    className = '',
    projectCard,
    number,
    ClientTag = 'h2',
}) => {
    const { media, color, client, lead, pathname } = projectCard;
    const moveContainerRef = useRef<HTMLDivElement>(null);
    const [mousePosition, setMousePosition] = useState<{ x: number; y: number } | null>(null);
    const [translate, setTranslate] = useState({ x: 0, y: 0 });

    const numberString = (number && number.toString()) || '';

    const handleMouseEnter = (event: MouseEvent) => {
        setMousePosition({
            x: event.clientX,
            y: event.clientY,
        });
    };

    const handleMouseLeave = () => {
        setMousePosition(null);
    };

    const handleMouseMove = (event: MouseEvent) => {
        if (mousePosition === null) return;

        const newMousePosition = { x: event.clientX, y: event.clientY };

        const deltaX = newMousePosition.x - mousePosition.x;
        const deltaY = newMousePosition.y - mousePosition.y;

        const newTranslate = {
            x: translate.x + deltaX / SLOWING_FACTOR,
            y: translate.y + deltaY / SLOWING_FACTOR,
        };

        setMousePosition(newMousePosition);
        setTranslate(newTranslate);
    };

    return (
        <Link
            to={pathname}
            className={`${container} ${className}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
        >
            {numberString && (
                <p className={numberBox}>
                    {numberString.length === 1 ? `0${numberString}` : numberString}
                </p>
            )}
            <div className={header}>
                <ClientTag className={clientName}>{client}</ClientTag>
                <p className={leadText}>{lead}</p>
            </div>
            <ImageWithErrorPlaceholder
                className={logoBox}
                ratioClass={logoRatio}
                objectFit="contain"
                media={media}
                relation={relations.projectLogo}
                useFallback={false}
            />
            <div className={imageContainer}>
                <div
                    ref={moveContainerRef}
                    style={{ transform: `translate(${translate.x}px,${translate.y}px)` }}
                >
                    <div className={imageBackground} style={{ background: color }}>
                        <ImageWithErrorPlaceholder
                            className={imageBox}
                            ratioClass={ratio}
                            objectFit="contain"
                            media={media}
                            relation={relations.coverImage}
                        />
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default ProjectCard;
